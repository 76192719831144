<template>
  <div class="myapp d-flex flex-column justify-content-center">
    <Header></Header>
    <hr />
    <h3 class="mx-auto my-3">Showcase Lines</h3>
    <ul class="list-group">
      <li
        class="list-group-item d-flex flex-column"
        v-for="(item, index) in lines"
        :key="index"
      >
        <div class="inline-block">
          <div class="float-left">{{ item["line"] }}</div>

          <div class="float-right delete" @click="deleteLine(index)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path
                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
              />
              <path
                fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              />
            </svg>
          </div>
          <div
            class="float-right mx-5"
            data-toggle="collapse"
            :data-target="'#collapseExample' + index"
            aria-expanded="false"
          ></div>
        </div>
      </li>
    </ul>
    <button
      class="btn btn-dark mx-auto my-4"
      v-if="!clicked"
      @click="clicked = true"
    >
      Add Line
    </button>
    <div v-else>
      <div class="form-control">
        <div class="form-group col-md-6">
          <input
            class="form-control"
            type="text"
            v-model="curr_line"
            placeholder="Showcase Line"
          />
        </div>
        <button class="btn btn-dark mx-auto my-4" @click="addLine">
          Add It
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import Header from "@/components/Header.vue";
import db from "../../firebase.js";
export default {
  components: {
    Header,
  },
  async mounted() {
    const docs = await db.collection("showcase_lines").get();
    this.lines = docs.docs.map((doc) => ({
      line: doc.data().line,
      id: doc.id,
    }));
    store.state.loading = false;
  },
  data() {
    return {
      lines: [],
      clicked: false,
      curr_line: "",
    };
  },

  methods: {
    deleteLine(index) {
      db.collection("showcase_lines")
        .doc(this.lines[index].id)
        .delete()
        .then(
          (res) => {
            this.lines.splice(index, 1);
          },
          (err) => {
            swal("Some error occured");
          }
        );
    },
    addLine() {
      db.collection("showcase_lines")
        .add({ line: this.curr_line })
        .then((docid) => {
          console.log("Line added");
          this.lines.push({
            id: docid,
            line: this.curr_line,
          });
          this.curr_line = "";
        })
        .catch((error) => swal(error));
    },
  },
};
</script>
<style scoped>
</style>
